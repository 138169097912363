import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueFeather from 'vue-feather';
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'
import './assets/index.css'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
const sweetAlertOptions = {
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#ff7674',
};
const app = createApp(App)
app.use(router)
app.use(autoAnimatePlugin)
app.use(VueSweetalert2, sweetAlertOptions)
app.component(VueFeather.name, VueFeather);
app.mount('#app')
window.Swal =  app.config.globalProperties.$swal;