<!-- <script setup>
import navBar from '@/components/navBar.vue'
import navigationBar from '@/components/navigationBar.vue'
</script> -->

<template>
  <!-- <navBar/> -->
  <div id="app-content">
    <router-view/>
  </div>
  <!-- <navigationBar/> -->
</template>