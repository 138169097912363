<script setup>
/* eslint-disable */
import { ref } from 'vue';
import GuideCard from './GuideCard.vue'

const config = ref({wifi: {}, firebase: {} })
const isGenerating = ref(false)
const codeGenerated = ref('')
async function generateCode(){
  isGenerating.value = true
  await delay(2000)
  codeGenerated.value = `
  #define WIFI_SSID "${config.value.wifi.ssid}"
  #define WIFI_PASSWORD "${config.value.wifi.ssid_password}"
  #define API_KEY "${config.value.firebase.api_key}"
  #define USER_EMAIL "${config.value.firebase.board_email}"
  #define USER_PASSWORD "${config.value.firebase.board_pass}"
  #define DATABASE_URL "${config.value.firebase.database_url}"
  String device_id = "${generateRandomString(6)}"
  `
  isGenerating.value = false
}

function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

function generateRandomString(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

</script>

<template>
  <div class="pt-10 pb-2">
    <GuideCard />
    
  </div>
  <div class="flex">
    <!-- Form -->
    <div class="basis-1/2 p-2">
      <div class="card">
        <div class="border-b border-zinc-200 pb-2">
          <p class="font-semibold text-xl">Board Secrets</p>
        </div>
        <div>
          <form @submit.prevent="generateCode()">
            <div class="mt-3">
              <p class="font-semibold text-xl mb-2">Wifi Configuration</p>
              <div class="py-4 px-2 border border-zinc-300 rounded-lg">
                <div class="w-1/2">
                  <label for="ssid" class="mt-4">
                    Wifi SSID
                  </label>
                  <input 
                    type="text" 
                    name="ssid" 
                    class="form-input"
                    v-model="config.wifi.ssid"
                  >
                </div>
                <div class="w-3/4">
                  <label for="ssid_password" class="mt-4">
                    Wifi Password
                  </label>
                  <input 
                    type="password" 
                    name="ssid_password" 
                    class="form-input"
                    v-model="config.wifi.ssid_password"
                  >
                </div>
              </div>
            </div>

            <div class="mt-6">
              <p class="font-semibold text-xl mb-2">Firebase Configuration</p>
              <div class="py-4 px-2 border border-zinc-300 rounded-lg">
                <div class="w-3/4">
                  <label for="api_key" class="mt-4">
                    API Key
                  </label>
                  <input 
                    type="text" 
                    name="api_key" 
                    class="form-input"
                    v-model="config.firebase.api_key"
                  >
                </div>
                <div class="w-3/4">
                  <label for="board_email" class="mt-4">
                    Board Email
                  </label>
                  <input 
                    type="email" 
                    name="board_email" 
                    class="form-input"
                    v-model="config.firebase.board_email"
                  >
                </div>
                <div class="w-3/4">
                  <label for="board_pass" class="mt-4">
                    Board Password
                  </label>
                  <input 
                    type="password" 
                    name="board_pass" 
                    class="form-input"
                    v-model="config.firebase.board_pass"
                  >
                </div>
                <div class="w-3/4">
                  <label for="database_url" class="mt-4">
                    Database URL
                  </label>
                  <input 
                    type="text" 
                    name="database_url" 
                    class="form-input"
                    v-model="config.firebase.database_url"
                  >
                </div>
              </div>
            </div>

            <div class="mt-3 flex justify-end">
              <button type="submit" class="btn btn-primary" v-auto-animate>
                <div v-if="isGenerating" class="flex">
                  <svg class="animate-spin mr-3 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Generating...
                </div>
                <span v-else>
                  Generate Code
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- Code -->
    <div class="basis-1/2 p-2">
      <div class="card">
        <div class="border-b border-zinc-200 pb-2">
          <p class="font-semibold text-xl">Code</p>
        </div>
        <div>
          <textarea name="generated_code" 
            id="" cols="30" rows="10" 
            class="border border-zinc-200 rounded-lg w-full h-[50vh]"
            v-model="codeGenerated"
          >
          </textarea>
        </div>
      </div>
    </div>
  </div>
</template>